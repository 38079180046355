let key = {};
var Envname ='demo';
var Front_URL = "";
var API_URL = "";
var IPFS_IMG ="";
  if (Envname == "demo") {
    console.log("Set demo Config");
    API_URL = "https://api-dedice.maticz.in/";
    Front_URL = "https://decentradice.io";
    IPFS_IMG = "https://ipfs.io/ipfs";
    key = {
      Front_URL        : Front_URL,
      IPFS_IMG         : IPFS_IMG,
      baseUrl          : API_URL,
      ownerAddr: "0x9Be2D469f49cdFACEB86B01C853bbc7910d4AdFa",
    single_contract: "0x53638056Cfd75023CfceFdF71466e0413B93B571",
    multiple_contract: "0xf6FFa015BfFFf65c621c3306BD3B8D9804b9Aeb7",
    lotterAdmin:"0x9Be2D469f49cdFACEB86B01C853bbc7910d4AdFa",
    ContractAddress: "0x9AF7c2F3fDFF4d211892Ef4B62De09bEc0fB4808",
      network: 56,
    provider: "https://bsc-dataseed1.binance.org:443",
    };
  }
  if (Envname == "production") {
    console.log("Set demo Config");
    API_URL = "https://lottery.refitplusdefi.com";
    Front_URL = "https://lottery.refitplusdefi.com/";
    IPFS_IMG = "https://ipfs.io/ipfs";
    key = {
      Front_URL        : Front_URL,
      IPFS_IMG         : IPFS_IMG,
      baseUrl          : API_URL,
      ownerAddr: "0x5c48cD9bc4d8F338aD51E1F7Ee792e0d962960EB",
    single_contract: "0x53638056Cfd75023CfceFdF71466e0413B93B571",
    multiple_contract: "0xf6FFa015BfFFf65c621c3306BD3B8D9804b9Aeb7",
    lotterAdmin:"0x5c48cD9bc4d8F338aD51E1F7Ee792e0d962960EB",
    ContractAddress: "0xE49a379BEc747Eb2cced334819B4974531eD03FB",
      network: 97,
    provider: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    };
  }
// } else {
// if (process.env.NODE_ENV === "production") {
if(Envname=="local"){
  console.log("Set Development Config");
  Front_URL = "http://localhost:3001/Pankuku-NFT/";
  API_URL = "http://localhost";
  IPFS_IMG = "https://ipfs.io/ipfs";
  key = {
    Front_URL: Front_URL,
    IPFS_IMG: IPFS_IMG,
    baseUrl: `https://api-dedice.maticz.in/`,
    ownerAddr: "0x19057ceBff76b70ff8749254990141aA042F9981",
    single_contract: "0x53638056Cfd75023CfceFdF71466e0413B93B571",
    multiple_contract: "0xf6FFa015BfFFf65c621c3306BD3B8D9804b9Aeb7",
    lotterAdmin:"0x19057ceBff76b70ff8749254990141aA042F9981",
    network: 97,
    ContractAddress: "0x0a819f28Ce7B99aE5C08Da221a9D031A0B32733C",
    provider: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  };
}
// }

export default key;
// "homepage": "https://nft.pankuku.net/tfnukukanpnft",