import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";

// import components
import Admin from "./components/layouts/Admin";
import ConditionRoute from "./components/Route/ConditionRoute";
import LoginHistory from "./pages/LoginHistory/loginHistory";

import { AdminList, AdminAdd, AdminEdit } from "./pages/Admin";

// import pages
import LoginPage from "./pages/LoginPage/Login";
import ForgotPage from "./pages/ForgotPage/ForgotPassword";
import changePassword from "./pages/ForgotPage/changePassword";
import Dashboard from "./pages/Dashboard/Dashboard";

//Token
import HeaderList from "./pages/Header/Toplist";
import Headerburn from "./pages/Header/BurnList";
// community
import CommunitycategoryList from "./community/Category/CategoryList";
import CommunitycategoryEdit from "./community/Category/categoryedit";
import Communitycategoryadd from "./community/Category/categoryadd";

import CommunityUserList from "./community/Category/CommunityUserList";

//Lottery
import LotteryList from "./pages/Lottery/LotteryList";
import LotterySettings from "./pages/Lottery/addSettings";
import LotteryAdd from "./pages/Lottery/startLottery";
import store from "./store";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import globalStyle from "./style/global.style";
import { decodeJwt } from "./actions/jsonWebToken";

const history = createBrowserHistory();
const App = () => {
  const { isAuth } = store.getState().currentUser;
  useEffect(() => {
    if (isAuth != true && localStorage.admin_token) {
      decodeJwt(localStorage.admin_token, store.dispatch);
    }
  }, []);
  return (
    <Provider store={store}>
      <ThemeProvider theme={globalStyle}>
        <ToastContainer />
        {/* <Router history={history} basename={process.env.REACT_APP_BASENAME || "/ulosdewvhref"}> */}
        <Router history={history} basename={"/"}>
          <Switch>
            {/* Auth Route */}
            <ConditionRoute path="/login" component={LoginPage} type={"auth"} />
            <ConditionRoute
              path="/forgot"
              component={ForgotPage}
              type={"auth"}
            />
            <ConditionRoute
              path="/changepassword/:authToken"
              component={changePassword}
              type={"auth"}
            />
            {/* <ConditionRoute path="/changepassword" component={changePassword} type={"public"} /> */}
            {/* Auth Route */}
            {/* Private Route */}
            <ConditionRoute
              path="/dashboard"
              component={Dashboard}
              layout={Admin}
              type={"private"}
              store={store}
            />

            <ConditionRoute
              exact
              path="/LotteryList"
              component={LotteryList}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/LotterySettings"
              component={LotterySettings}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/LotteryAdd"
              component={LotteryAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/burnList"
              component={Headerburn}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/list"
              component={AdminList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/add"
              component={AdminAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/eidt/:userId"
              component={AdminEdit}
              layout={Admin}
              type={"private"}
            />
            {/* Token  */}
            <ConditionRoute
              exact
              path="/HeaderList"
              component={HeaderList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/loginhistory"
              component={LoginHistory}
              layout={Admin}
              type={"private"}
            />
            {/* Report */}
            <ConditionRoute
              exact
              path="/Communitycategorylist"
              component={CommunitycategoryList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/Communityaddcategory"
              component={Communitycategoryadd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/Communitycategoryedit/:userId"
              component={CommunitycategoryEdit}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/CommunityUserList"
              component={CommunityUserList}
              layout={Admin}
              type={"private"}
            />
            {/* Public Route */}
            {/* Public Route */}
            {/* <Route path="/login" component={LoginPage} /> */}
            <Redirect from="/" to="/login" />
            {/* <Route path="/admin" component={Admin} /> */}
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
