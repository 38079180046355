// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {};

    if (isEmpty(value.endTime)) {
        errors.endTime = "Please enter endTime"
    }
    if (isEmpty(value.ticketPice)) {
        errors.ticketPice = "Please Enter ticketPice"
    }
    if (isEmpty(value.rew1)) {
        errors.rew1 = "Please Enter rew1"
    } 
    if (isEmpty(value.rew2)) {
        errors.rew2 = "Please Enter rew2"
    }
    if (isEmpty(value.rew3)) {
        errors.rew3 = "Please Enter rew3"
    }
    if (isEmpty(value.rew4)) {
        errors.rew4 = "Please Enter rew4"
    }
    if (isEmpty(value.rew5)) {
        errors.rew5 = "Please Enter rew5"
    }
    if (isEmpty(value.rew6)) {
        errors.rew6 = "Please Enter rew6"
    }

    return errors;
}

export default validation;