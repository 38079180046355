// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money, 
  History,
  Email,
  Help,
  LiveHelp, 
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon,
} from "@material-ui/icons";

import AdjustIcon from "@material-ui/icons/Adjust";

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "forgot",
    path: "/forgot",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "change-password",
    path: "/changepassword/:authToken",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  {
    id: "security",
    path: "/securityType",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  /* Dashboard */
  {
    id: "dashboard",
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    isSideMenu: true,
    isEdit: false,
    isRestricted: "single",
  },

  /*Admin  */
  {
    id: "admin",
    path: "/list",
    name: "Admin Management",
    icon: List,
    isSideMenu: true,
    isEdit: true,
    isRestricted: "multi",
    child: [
      {
        id: "add",
        path: "/add",
      },
      {
        id: "edit",
        path: "/edit/:userId",
      },
    ],
  },

  /* Dashboard */
  {
    path: "#",
    id: "dashboard",
    name: "Dashboard",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Dashboard Lists",
        path: "/dashboard",
        icon: Person,
      },
    ],
  },

  /* User */

  // {
  //   path: "#",
  //   id: "category",
  //   name: "Category Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Category Lists",
  //       path: "/categorylist",
  //       icon: Person,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   id: "Bid",
  //   name: "Bid Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Bid Lists",
  //       path: "/bidlist",
  //       icon: Person,
  //     },
  //   ],
  // },

  // {
  //   path: "#",
  //   id: "token",
  //   name: "Token Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Token Lists",
  //       path: "/TokenList",
  //       icon: Person,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   id: "emailtemplate",
  //   name: "Email Template",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Email Template List",
  //       path: "/emailTemplate",
  //       icon: Person,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   id: "user",
  //   name: "User Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "User Lists",
  //       path: "/userList",
  //       icon: Person,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   id: "Fee Setting",
  //   name: "Fee Setting Details",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isDisplay: true,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Fee Setting",
  //       path: "/setting",
  //       icon: Person,
  //       visible: true,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   id: "Report",
  //   name: "Report Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Report List",
  //       path: "/reportList",
  //       icon: Person,
  //     },
  //   ],
  // },
  {
    path: "#",
    id: "Lottery",
    name: "Lottery Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Lottery List",
        path: "/LotteryList",
        // path: "#",
        icon: Person,
      },
      {
        name: "Lottery Settings",
        path: "/LotterySettings",
        // path: "#",
        icon: Person,
      },
      {
        name: "Lottery Add",
        path: "/LotteryAdd",
        // path: "#",
        icon: Person,
      },
    ],
  },
  // {
  //   path: "#",
  //   id: "Contactus",
  //   name: "Contactus",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Contactus",
  //       path: "/Contactus",
  //       icon: Person,
  //     },
  //   ],
  // },
];

export default dashboardRoutes;
