import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import isEmpty from "../../lib/isEmpty";
import isValidation from "./validation";

import DateTimePicker from "react-datetime-picker";

import Web3 from "web3";
import "@metamask/legacy-web3";

import { addLottery } from "./../../actions/admin";

import lotteryABI from "../../ABI/Lottery.json";

import config from "../../lib/config";
import { array } from "prop-types";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  lotteryId: "",
  endTime: "",
  ticketPice: "",
  discount: 300,
  rew1: "",
  rew2: "",
  rew3: "",
  rew4: "",
  rew5: "",
  rew6: "",
  fees: 3000,
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const history = useHistory();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [submitButton, setsubmitButton]= useState(false);

  useEffect(() => {}, []);

  // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
  };

  const {
    lotteryId,
    endTime,
    ticketPice,
    discount,
    rew1,
    rew2,
    rew3,
    rew4,
    rew5,
    rew6,
    fees,
  } = formValue;

  async function convert(n) {
    try {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    } catch (err) {
        return 0;
    }

}

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let reqData = {
      endTime,
      ticketPice,
      rew1,
      rew2,
      rew3,
      rew4,
      rew5,
      rew6,
    };
    console.log(reqData,'formdata')
    let error = isValidation(reqData);
    console.log(error,'errors')
    if(!isEmpty(error)){
      setValidateError(error)
      toast.warning("Please Enter Required Fields", toasterOption);
      return false
    }
    setsubmitButton(true);
    var arra = [];
    arra.push(parseInt(rew1));
    arra.push(parseInt(rew2));
    arra.push(parseInt(rew3));
    arra.push(parseInt(rew4));
    arra.push(parseInt(rew5));
    arra.push(parseInt(rew6));

    if (!window.ethereum) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setsubmitButton(false);
      return false;
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setsubmitButton(false);
      return false;
    }
    if (
      parseInt(rew1) +
        parseInt(rew2) +
        parseInt(rew3) +
        parseInt(rew4) +
        parseInt(rew5) +
        parseInt(rew6) >
        10000 ||
      parseInt(rew1) +
        parseInt(rew2) +
        parseInt(rew3) +
        parseInt(rew4) +
        parseInt(rew5) +
        parseInt(rew6) <
        10000
    ) {
      toast.warning("Total spilt up must be 10000", toasterOption);
      setsubmitButton(false);
      return false;
    }

    var currentDate = new Date();

    // var timeMIn = new Date(currentDate.setMinutes(currentDate.getHours() + 4));
    var timeMIn= new Date(); 
   
    // var timeMIn = new Date(
    //   currentDate.setHours((currentDate.getHours()) + 4)
    // );
   
    var timeMax = new Date(currentDate.setDate((currentDate.getDate()) + 4));

    var endDate = new Date(endTime * 1000);

    console.log(endDate, timeMIn, timeMax, "saranTestingLOggggk");

    // if (endDate < timeMIn) {
    //   toast.warning("It must be greater than 4 hours", toasterOption);
    //   setsubmitButton(false);
    //   return false;
    // }

    // if (endDate > timeMax) {
    //   toast.warning("It must be lesser than 4 days", toasterOption);
    //   setsubmitButton(false);
    //   return false;
    // }
  
    const Contract = new web3.eth.Contract(lotteryABI, config.ContractAddress);
    let convertamout=ticketPice * 1000000000000000000;
    var amountFIn = await convert(convertamout);
    var result = await Contract.methods
      .startLottery(
        endTime,
        amountFIn.toString(),
        0, // discount
        arra,
        0 // treasury fee
      )
      .send({ from: currAddr }).then(async (result)=>{
        var ticketId = result.events.LotteryOpen.returnValues.lotteryId;

      var transactionHash = "";

      if (result.transactionHash) {
        transactionHash = result.transactionHash;
      } else {
        transactionHash = result;
      }

      let reqData = {
        ticketId,
        endTime,
        ticketPice,
        discount,
        rew1,
        rew2,
        rew3,
        rew4,
        rew5,
        rew6,
        fees,
        transactionHash,
        endDate,
      };

      let { error } = await addLottery(reqData);

      setsubmitButton(false);
      toast.success("Lottery updated successfully", toasterOption);
       history.push("/LotteryList");
      }).catch((error)=>{
        setsubmitButton(false); 
        setValidateError(error);
        toast.warn("Ooops! Rejected", toasterOption);
      })

    

    // if (isEmpty(error)) {
    //   console.log("saran-------------");
      
     
    // } else {
    //   setsubmitButton(false); 
    //   setValidateError(error);
    // }
    // console.log("error");
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Start Lottery</h4>
                <p className={classes.cardCategoryWhite}>Start Lottery</p>
              </CardHeader>
              <CardBody>
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="lotteryId"
                      onChange={onChange}
                      id="lotteryId"
                      value={lotteryId}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.lotteryId && (
                      <span className="text-danger">
                        {validateError.lotteryId}
                      </span>
                    )}
                  </GridItem>
                </GridContainer> */}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="End time in timestamp"
                      onChange={onChange}
                      id="endTime"
                      value={endTime}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.endTime && (
                      <span className="text-danger">
                        {validateError.endTime}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Ticket Prize"
                      onChange={onChange}
                      id="ticketPice"
                      value={ticketPice}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.ticketPice && (
                      <span className="text-danger">
                        {validateError.ticketPice}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="discount"
                      onChange={onChange}
                      id="discount"
                      value={discount}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.discount && (
                      <span className="text-danger">
                        {validateError.discount}
                      </span>
                    )}
                  </GridItem>
                </GridContainer> */}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="First Prize %"
                      onChange={onChange}
                      id="rew1"
                      value={rew1}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.rew1 && (
                      <span className="text-danger">{validateError.rew1}</span>
                    )}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Second Prize %"
                      onChange={onChange}
                      id="rew2"
                      value={rew2}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.rew2 && (
                      <span className="text-danger">{validateError.rew2}</span>
                    )}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Third Prize %"
                      onChange={onChange}
                      id="rew3"
                      value={rew3}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.rew3 && (
                      <span className="text-danger">{validateError.rew3}</span>
                    )}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Fourth Prize %"
                      onChange={onChange}
                      id="rew4"
                      value={rew4}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.rew4 && (
                      <span className="text-danger">{validateError.rew4}</span>
                    )}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Fifth Prize %"
                      onChange={onChange}
                      id="rew5"
                      value={rew5}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.rew5 && (
                      <span className="text-danger">{validateError.rew5}</span>
                    )}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Sixth Prize %"
                      onChange={onChange}
                      id="rew6"
                      value={rew6}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.rew6 && (
                      <span className="text-danger">{validateError.rew6}</span>
                    )}
                  </GridItem>
                </GridContainer>

                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="fees"
                      onChange={onChange}
                      id="fees"
                      value={fees}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.fees && (
                      <span className="text-danger">{validateError.fees}</span>
                    )}
                  </GridItem>
                </GridContainer> */}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {/* <Select
                        onChange={handleChange}
                        options={categorytype}
                        label="select type"
                         formControlProps={{
                        fullWidth: true
                      }}
                      /> */}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                {submitButton && (
                            <i class="fas fa-spinner fa-spin mr-2"></i>
                          )}Add
                </Button>
                {submitButton && (<lable>Don't refersh when page loading...</lable>)}
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
