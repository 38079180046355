// import package
import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
// import material ui
import {
    Store,
    Accessibility
} from "@material-ui/icons";
import { Icon } from "@material-ui/core";

// import componet
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

// import action
import {getWalletCount , getTokenCount,getBidderCount} from '../../actions/dashboard'
import config from '../../lib/config';
import LotteryABI  from '../../ABI/Lottery.json';

const DashboardCount = (props) => {
    // props
    const { classes }                   = props;
    const [walletcount, setWalletCount] = useState(0)
    const [tokenCount, setTokenCount]   = useState(0)
    const [bidderCount, setBidderCount] = useState(0)

    
    const fetchBidderCnt = async () => {
        if(window.web3){
            let web3 = new Web3(config.provider);
            let lotteryContract = new web3.eth.Contract(LotteryABI,config.ContractAddress);
            let totaltickets = await lotteryContract.methods.currentTicketId().call()
            setBidderCount(totaltickets);
        }else{
            console.log("web3 not support");
        }
    }
    const fetchWalletCnt = async () => {
        const { status, loading, error, result } = await getWalletCount()
        if (status == 'success') {
            setWalletCount(result.tokenData)
        }
    }
    useEffect(() => {
        fetchBidderCnt();
        fetchTokenCnt();
        fetchWalletCnt();
    }, [])

    const fetchTokenCnt = async () => {
        if(window.web3){
        let web3 = new Web3(config.provider);
        let lotteryContract = new web3.eth.Contract(LotteryABI,config.ContractAddress);
        let totallottery = await lotteryContract.methods.currentLotteryId().call()
            setTokenCount(totallottery);
    }else{
        console.log("web3 not support");
    }
    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="danger" stats icon>
                        <CardIcon color="danger">
                            <Icon>info_outline</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}>Total Lottery Rounds</p>
                        <h3 className={classes.cardTitle}>
                            {tokenCount}
                        </h3>
                    </CardHeader>
                    <CardFooter stats></CardFooter>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                            <Store />
                        </CardIcon>
                        <p className={classes.cardCategory}>Current Round Tickets Count</p>
                        <h3 className={classes.cardTitle}>
                            {bidderCount}
                        </h3>
                    </CardHeader>
                    <CardFooter stats></CardFooter>
                </Card>
            </GridItem>
           {/* <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="info" stats icon>
                        <CardIcon color="info">
                            <Accessibility />
                        </CardIcon>
                        <p className={classes.cardCategory}>Wallet</p>
                        <h3 className={classes.cardTitle}>
                            {walletcount}
                        </h3>
                    </CardHeader>
                    <CardFooter stats></CardFooter>
                </Card>
            </GridItem>  */}
        </GridContainer>
    )
}

export default DashboardCount;