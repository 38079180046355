// import axios
import axios from "axios";

// import lib
import config from "../lib/config";
import { getAuthToken } from "../lib/localStorage";

axios.defaults.headers.common["Authorization"] = getAuthToken();

export const getAllAdmin = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/admin`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addNewAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminapi/admin`,
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getcategory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/categorydetail`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const updateSetting = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/updatesetting`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getsettinglist = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/getsettings`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getWallet = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/getwallet`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getAllartlist = async (filterData) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/getAllartlist`,
      params: filterData,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getproductdetails = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/getproductdetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const getBurnFavourite = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/getBurnFavourite/` + id,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });

    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const deletewallet = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/deletewallet`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const addwallet = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/addwallet`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log("result of Data", respData);
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const editAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `${config.API_URL}/adminapi/admin`,
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getSingleAdmin = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/singleAdmin/${id}`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getlotterylist = async (token, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/getlotterylist`,
    });
    return {
      loading: false,
      userValue: respData.data.addressData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const completeTicket = async (data, dispatch) => {
  console.log("yes");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/completeTicket`,
      data,
    });
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const closeComTicket = async (data, dispatch) => {
  console.log("yessaran");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/closeComTicket`,
      data,
    });

    console.log(respData, "respDatarespDatarespDatarespDatarespData");
    return {
      loading: false,
      userValue: respData.data.userValue,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addLottery = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/addLottery`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addLotterySettings = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/addLotterySettings`,
      data,
    });

    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAddress = async (token, dispatch) => {
  console.log("yes", `${config.baseUrl}/v2/getAddress`);
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/getAddress`,
    });
    return {
      loading: false,
      userValue: respData.data.addressData,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
