import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
// import Modal from '@material-ui/core/Modal';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getAllartlist,getBurnFavourite} from './../../actions/admin';
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import { toastAlert } from '../../lib/toastAlert'
import CardHeader from "components/Card/CardHeader.js";
import * as moment from "moment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import StarIcon from '@material-ui/icons/Star';
import config from '../../lib/config';
// import ABI from '../../ABI/ABI.json';
// import SingleAbi from "../../ABI/Single.json"

import { Modal, Button } from 'react-bootstrap/';
import libconfig from '../../lib/config'



var web3 = new Web3(window.ethereum);


const AIDISINGLE = libconfig.single_contract
const AIDIMULTIPLE = libconfig.multiple_contract


// const NEWSINGLESMARTCONTRACT = new web3.eth.Contract(SingleAbi, AIDISINGLE)
// const NEWMULTIPLESMARTCONTRACT = new web3.eth.Contract(ABI, AIDIMULTIPLE)



const rows = [

];


const headCells = [
    { id: 'category', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'Price', numeric: true, disablePadding: false, label: 'Price' },
    { id: 'creator', numeric: true, disablePadding: false, label: 'Creator' },
    { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
    { id: 'Date', numeric: true, disablePadding: false, label: 'Date' },
    // { id: 'accountType', numeric: true, disablePadding: false, label: 'Link' },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 76%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const customStyles = customStyle();
    const [show, setShow] = useState(false);
    const [burnpopshow, setburnpopshow] = useState(false);
    const classesSearch = useToolbarStyles();
    const [data, setData] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userDetails, setUser] = useState([]);
    const [burnlist, setburnlist] = useState([]);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [typingTimeout, setTypingTimeout] = useState(0)

    const [currentItem, set_currentItem] = useState({});
    const [singleowner, setsingleowner] = React.useState("");
    const [multipleowner, setmultipleowner] = React.useState("");
    const [nooftoken, setnooftoken] = React.useState(1);
    const [currentid, setCurrentid] = React.useState(1);
    const [currenttype, setCurrenttype] = React.useState(1);
    // const [show, setShow] = useState(false);

    function setview(id) {
        if (id != '') {
            // window.location="/cratfidiatnm/viewdetails/"+id;
            // history.push('/cratfidiatnm/viewdetails/'+id)
        }

    }

   // async function handleShow(idd) {
        // var IndexVal = burnlist.findIndex(val => val.tokenCounts == idd);
        // if (IndexVal > -1) {
        //     set_currentItem(burnlist[IndexVal])
        //     var curitem = burnlist[IndexVal]
        //     console.log("currAddrcurrAddr", curitem)

        //     if (window.ethereum) {
        //         var web3 = new Web3(window.ethereum)
        //         var currAddr = window.web3.eth.accounts[0];
        //         console.log("currAddrcurrAddr", currAddr,singleowner)
        //         console.log("currAddrcurrAddr singleowner", singleowner)

        //         if (curitem.type == 721) {
        //             if ((currAddr.toLowerCase()).toString() == (singleowner.toLowerCase()).toString()) {
        //              await   NEWSINGLESMARTCONTRACT.methods.burn(
        //                     curitem.tokenCounts 
        //                 ).send({
        //                     from:currAddr
        //                 }).then(async(result)=>{
        //                     if(result){
        //                         var postData = {
        //                             tokenOwner: curitem.tokenOwner,
        //                             tokenCounts: curitem.tokenCounts,
        //                             blockHash: result.blockHash,
        //                             transactionHash: result.transactionHash,
        //                             contractAddress:curitem.contractAddress,
        //                             type:curitem.type,
        //                             balance:0,
        //                             currAddr:currAddr,
        //                             quant:curitem.balance
        //                             }
        //                             var updateBurnField = await BurnField(postData)
        //                             if(updateBurnField){
        //   toastAlert("success", "Successfully Updated", "fee"); 

        //                             }else{
        //                                 toastAlert("error", "Unable to Save", "fee");
        //                             }
        //                     }
        //                 })
        //             } else {
        //                 toastAlert("error", "Please Connect with Owner Account", "fee");
        //             }
        //         }else {
        //             setburnpopshow(true)

        //         }
        //     }


        // }



        // if(item.type==721){
        // //contract
        // }else
        // if(item.type == 1155){
        // setShow(true)
        //[popuo  --- admin quantity delete.. number]
        // }
        // if(item.tokendetails.type=="erc721"){
        //     type = 721
        // }else{
        //     type =1155
        // }
        // if(window.web3.currentProvider.networkVersion == network){
        // var web3 = new Web3(window.ethereum);
        // await window.ethereum.enable();
        // var web3  = new Web3(window.web3.currentProvider);
        // var nftContract = new web3.eth.Contract(ABI,smartContract);
        // var currAddr = await web3.eth.getAccounts();
        // var owneraddr = await nftContract.methods.owner().call();
        // console.log(
        //     item.tokenOwner,
        //      item.tokenCounts,
        //     item.tokendetails.contractAddress,
        //     type,
        //     item.balance,"===============================================")
        // if(currAddr[0]==owneraddr){
        //   var result = await nftContract.methods.burn(
        //     item.tokenOwner,
        //     item.tokenCounts,
        //     item.tokendetails.contractAddress,
        //     type,
        //     item.balance
        //   ).send({
        //     from: currAddr[0]
        //   });
        //   console.log(result,"===========================================burnresult")
        //   var data={
        //     id:idd,
        //     tokenName:item.tokendetails.tokenName
        //   }
        //   var res = await adminburntoken(data);
        //   toastAlert("success", "Successfully changed", "fee"); 
        //   getAllcms();
        //   }else{
        //   toastAlert("error", "Please login "+owneraddr, "fee");
        //   }  
        // }else{
        //     toastAlert("error", "Please choose binance network", "fee");
        // }   
  //  };


    const handleChange = (event) => {
        const { name, value } = event.target;
        const self = this;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        //alert(value)

        setSearchQuery(value)
        setTypingTimeout(setTimeout(function () {
            getAllcms({ 'search': value })
        }, 1000))
    }


    const getAllcms = async (search = {}) => {
        let filterData = search;
        if (filterData.search == '') {
            filterData = {}
        }
        var res = await getAllartlist(filterData);
        console.log('result===================', res);
        if (res.result) {
            setburnlist(res.result.data);
            console.log('result of')
        }

    }

    const handleRequestSort = (event, property) => {
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    useEffect(() => {
        getAllcms();
        getownerdata()
    }, [])


    async function getownerdata() {
        if (!window.ethereum) {
            toastAlert("error", "Please Connect the Wallet", "fee");
            return false;
        }

        // var singleowner = await NEWSINGLESMARTCONTRACT.methods.owner().call();
        // setsingleowner(singleowner)

        // var multilpeowner = await NEWMULTIPLESMARTCONTRACT.methods.owner().call();
        // setmultipleowner(multilpeowner)

    }



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    async function ClosehandleBurn() {
        setburnpopshow(!burnpopshow)
    }
    async function handleShow(idd) {
        setCurrentid(idd)
        var IndexVal = burnlist.findIndex(val => val.tokenCounts == idd);
        if (IndexVal > -1) {
            set_currentItem(burnlist[IndexVal])
            var curitem = burnlist[IndexVal];
            setCurrenttype(curitem.type)
        }
    }
    async function burn(){
        var IndexVal = burnlist.findIndex(val => val.tokenCounts == currentid);
        if (IndexVal > -1) {
            set_currentItem(burnlist[IndexVal])
            var curitem = burnlist[IndexVal];

            if(nooftoken>curitem.balance){
                toastAlert("error",'Available Tokens'+""+curitem.balance, "fee"); 
                return false;
              }
        var postData = {
            nooftoken  : nooftoken,
            tokenCounts:curitem.tokenCounts,
            tokenOwner: curitem.tokenOwner,
            contractAddress:curitem.contractAddress,
            type:curitem.type,
          }
          let payload = {
            nooftoken  : nooftoken,
            tokenCounts:curitem.tokenCounts,
            tokenOwner: curitem.tokenOwner,
            contractAddress:curitem.contractAddress,
            type:curitem.type,

          }
        // var updateBurnField = await adminburntoken(postData)
        // if(updateBurnField){
        //     toastAlert("success", "Successfully burned", "fee"); 
        //     setTimeout(() => { window.location.reload(false)
        //       }, 2000);
          
        // }
        }
    }
    const inputChange = (e) => {
        if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
          var value = e.target.value;
          switch (e.target.name) {
            case 'nooftoken':
              if (isNaN(value) == true) {
                // ValidateError.tokenCount = 'Enter Valid price';
                // setValidateError(ValidateError);
                setnooftoken('0');
              }
              else {
                // delete ValidateError.tokenCount;
                // setValidateError(ValidateError);
                setnooftoken(value);
              }
              break;
           
            default:
          }
          // CreateItemValidation();
        }
      }
      async function lock(id){
        var res = await getBurnFavourite(id);
          if(res && res.result){
            toastAlert('success',"Added to Tokenaccess", 'category')
          }else{
            toastAlert('success', "Removed to TokenCreators", 'category')
        }         
    }
    const isSelected = (name) => selected.indexOf(name) !== -1;
    return (
        <div className={classes.root}>

           {/*  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Burn or Boui Details</h5>
                </div>
                </div>
            </div>
            </div>*/}
        
            <Paper className={classes.paper}>
                <Toolbar
                    className={clsx(classesSearch.root, {
                        [classesSearch.highlight]: selected.length > 0,
                    })}
                >
                    <Typography className={classesSearch.title} variant="h6" id="tableTitle" component="div">
                    Burn or Boui Details
                    </Typography>
                    {/*  <div className={classes.flexHeader}>
                            <Link to={'/Addcms'} className={classes.btnPrimary}>Add CMS</Link>
                        </div>*/}

<div className={classesSearch.searchWrapper}>
                        <FormControl
                            className={classesSearch.margin + " " + classesSearch.search + " " + customStyles.formControl}
                        >
                            <Input placeholder="Search"
                                classes={{

                                    root: customStyles.marginTop,
                                    disabled: customStyles.disabled,
                                    underline: customStyles.underline
                                }}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </div>

                </Toolbar>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {burnlist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) =>  {
                                var type = "";
                                if (row.fixed == 1) {
                                    type = "Fixed Auction"
                                } else if (row.timed == 1) {
                                    type = "Timed Auction"
                                } else {
                                    type = "Reverse Auction"
                                }
                                return (
                                    <TableRow>
                                        <TableCell align="center">{row && row.tokendetails && row.tokendetails.tokenName}</TableCell>
                                        <TableCell align="center">{row.tokenPrice}</TableCell>
                                        <TableCell align="center"><span className="token_address">{row.ownerinfo && row.ownerinfo.name ? row.ownerinfo.name : row.tokenOwner}</span></TableCell>
                                        <TableCell align="center">{row.type}</TableCell>
                                        <TableCell align="center">{moment(row.timestamp).format(
                                            "DD-MM-YYYY  h:mm a "
                                        )}</TableCell>
                                        {/*<TableCell align="center"><a href={"http://18.189.151.46/info/"+row._id} target="_blank">Link</a></TableCell>*/}
                                        <TableCell align="center">
                                        {row.burnFavourite == true ? 
                                             <Link onClick={() => lock(row._id)}><StarIcon style={{ color: "gold" }}/></Link>
                                             :
                                              <Link onClick={() => lock(row._id)}><StarIcon style={{ color: "109ebf" }}/></Link>
                                        }
                                           {/*  <Link className="btn btn-primary btn-sm mr-2" to={'/viewdetails/' + row._id}>
                                                View</Link>*/}
                                            {/*<div>
                                            {row.balance!=0 ?
                                            <Link  type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModal" onClick={()=>handleShow(row.tokenCounts)}>Burn</Link>
                                                :
                                                 <span>Restrict</span>                                          
                                            }
                                        </div>*/}
                                            </TableCell>
                                    </TableRow>
                                );
                            })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={burnlist && burnlist.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>


        </div>
    );
}